import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DropDownItem } from "../../pojo/DropDownItem";
import { ServerSidePagination } from "../../pojo/ServerSidePagination";

@Component({ template: "" })
export class S25DropdownAbstract {
    @Input() staticItems?: DropDownItem[];
    @Input() chosen: DropDownItem;
    @Input() onSelect: Function;
    @Input() searchEnabled?: boolean;
    @Input() hasFav: boolean;
    @Input() autoOpen?: boolean;
    @Input() focusFirst?: boolean;
    @Input() alwaysOpen?: boolean;
    @Input() hideSearch?: boolean;
    @Input() apiBean?: any;
    @Input() serverSide?: ServerSidePagination; //only used for "expensive" search components (event, location, resource, org, contact, etc)
    @Input() resetSelectedOnCleanup: boolean;
    @Input() useSecurity?: boolean; //only used for master defn dropdowns
    @Input() activeOnly?: boolean; //only used for master defn dropdowns
    @Input() omitReadOnly?: boolean; //search criteria with 'tags' enabled might have a 'read only' option
    @Input() onLoad?: Function;
    @Input() customFilterValue: string;
    @Input() ariaLabel?: string;

    @Output() chosenChange = new EventEmitter<DropDownItem>();

    items: DropDownItem[];

    onChosenChange(data: any) {
        this.chosenChange.emit(data);
    }
}
